//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DropdownSelect from '~/components/UI/Dropdown/DropdownSelect.vue'

export default {
  name: 'DropdownFilter',
  components: { DropdownSelect },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      default: '',
    },
    selected: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    count() {
      return this.selected.filter((item) => item.key === this.id).length
    },
    isIcon() {
      if (!this.count) {
        return {
          icon: 'default',
          borderColor: this.$color.dark,
          stroke: this.$color.dark,
        }
      } else if (this.count < this.filters[this.id].length) {
        return {
          icon: 'line',
          iconW: '6px',
          iconH: '2px',
          borderColor: this.$color.primary,
          stroke: this.$color.primary,
        }
      }
      return {
        icon: 'check',
        iconW: '75%',
        iconH: '75%',
        borderColor: this.$color.primary,
        bg: this.$color.primary,
        stroke: this.$color.white,
      }
    },
  },
  methods: {
    isChecked(item) {
      const index = this.selected.findIndex(
        (i) => i.key === this.id && i.item === item,
      )
      return index !== -1
    },
    onSelect(value) {
      this.$emit('set:filter', { key: this.id, item: value })
    },
    onSelectAll() {
      this.$emit('set:options', this.id)
    },
  },
}
