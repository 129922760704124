//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DropdownFilter from './components/DropdownFilter.vue'
import DropdownSelect from '~/components/UI/Dropdown/DropdownSelect.vue'
export default {
  name: 'FiltersLayout',
  components: {
    DropdownFilter,
    DropdownSelect,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
      require: true,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    sort: {},
    type: Array,
    default: () => [],
  },
  data() {
    return {
      selectedItems: [],
    }
  },
  computed: {
    visibleFilters() {
      if (!this.filters.attributes) return {}
      const attributes = {}
      const keys = Object.keys(this.filters.attributes).slice(0, 3)
      for (const key of keys) attributes[key] = this.filters.attributes[key]
      return attributes
    },
  },
  mounted() {
    Object.entries(this.params.attributes).forEach(([key, value]) => {
      for (const item of value) {
        this.selectedItems.push({ key, item })
      }
    })
  },
  methods: {
    onSelect({ key, item }) {
      this.$emit('set:filter', key, item)
      const selected = { key, item }
      const index = this.selectedItems.findIndex(
        (select) => select.key === key && select.item === item,
      )
      if (index === -1) this.selectedItems.push(selected)
      else this.selectedItems.splice(index, 1)
    },
    setFiltersOptions(key) {
      this.$emit('set:options', key)
      const selected = this.selectedItems.filter(
        (select) => select.key === key,
      ).length
      if (
        !selected ||
        (selected && selected < this.filters.attributes[key].length)
      ) {
        this.selectedItems = this.selectedItems.filter(
          (select) => select.key !== key,
        )
        this.filters.attributes[key].forEach((item) => {
          const select = { key, item }
          this.selectedItems = [...this.selectedItems, select]
        })
      } else if (selected === this.filters.attributes[key].length) {
        this.selectedItems = this.selectedItems.filter(
          (select) => select.key !== key,
        )
      }
    },
  },
}
