var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"footer"},[_c('div',{staticClass:"container"},[_c('div',{class:[
        'wrapper',
        { 'wrapper--accordion': _vm.accordion } ],attrs:{"id":"wrapper"}},[_c('div',{class:[
          'footer__navbar-wrapper',
          { 'footer__navbar-wrapper--accordion': _vm.accordion }
        ],attrs:{"id":"footerNavbar"}},[_vm._l((_vm.links),function(link,i){return _c('footer-list-navbar',{key:i,attrs:{"title":link.title,"links":link.children,"accordion":_vm.accordion}})}),_vm._v(" "),_c('footer-list-navbar',{attrs:{"title":_vm.$t('ourOffice'),"accordion":_vm.accordion},scopedSlots:_vm._u([{key:"inner",fn:function(){return [_c('a',{staticClass:"footer-location",attrs:{"target":"_blank","href":"https://www.google.com.ua/maps/place/Bojemskiego+25,+42-202+Cz%C4%99stochowa,+%D0%9F%D0%BE%D0%BB%D1%8C%D1%88%D0%B0/@50.7867396,19.1840466,17z/data=!3m1!4b1!4m5!3m4!1s0x4710b514db07e539:0xc179eb46a2af7449!8m2!3d50.7867362!4d19.1862353?hl=ru"},domProps:{"innerHTML":_vm._s(_vm.settings.contacts_address)}})]},proxy:true}])})],2),_vm._v(" "),_c('footer-contacts',{class:[
          'footer-contacts',
          { 'footer-contacts--left-position': !_vm.accordion },
          { 'footer-contacts--right-position': _vm.accordion } ],attrs:{"id":"footerContacts"}})],1),_vm._v(" "),_c('div',{staticClass:"policy"},[_c('div',{staticClass:"policy-text--wrapper"},[_c('p',{staticClass:"policy-text"},[_vm._v("© 2022 "+_vm._s(_vm.$t('furnitureText')))])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }