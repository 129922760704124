//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, email, minLength, maxLength, helpers } from 'vuelidate/lib/validators'
import validationWatcher from '~/mixins/validationWatcher'
import { HAS_TOKEN } from '~/store/auth/types.auth'
const emailValidationText = helpers.regex('email', /^[a-zA-Z0-9._@\s]*$/)

export default {
  name: 'FooterContacts',
  mixins: [validationMixin, validationWatcher],
  data() {
    return {
      form: {
        email: '',
      },
      subscribeText: '',
      error: null,
    }
  },
  validations: {
    form: {
      email: { required, email, minLength: minLength(5), maxLength: maxLength(64), emailValidationText },
    },
  },
  watch: {
    subscribeText() {
      setTimeout(() => {
        this.subscribeText = ''
      }, 4000)
    },
  },
  computed: {
    ...mapState({
      settings: state => state.settings.settings,
    }),
    isAuth() {
      return this.$store.getters[`auth/${HAS_TOKEN}`]
    },
  },
  methods: {
    async subscribe() {
      if(!this.isAuth) {
        this.$root.$emit('handleAuthModal')
        this.form.email = ''
        return
      }
      this.setValidationSettings(true)
      if (!this.checkForm()) return
      this.setValidationSettings(false)
      const payload = {
        email: this.form.email
      }
      try {
        await this.$api.subscribe.subscribe(payload)
        this.$v.form.$reset()
        this.subscribeText = this.$t('thanksForSubscribing')
        this.form.email = ''
        this.error = null
      } catch (e) {
        const { response } = e
        if (response.status === 422) {
          this.error = { message: response.data.message }
        }
      }
    },
  }
}
