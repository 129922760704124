//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { IS_DESKTOP, IS_LAPTOP } from '~/store/device/type.device'
export default {
  name: 'FooterListNavbar',
  props: {
    title: {
      type: String,
      default: '',
      require: true,
    },
    links: {
      type: Array,
      default: () => [],
    },
    accordion: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {
    isLaptop() {
      return this.$store.getters[`device/${IS_LAPTOP}`]
    },
    isDesktop() {
      return this.$store.getters[`device/${IS_DESKTOP}`]
    },
  },

  watch: {
    open() {
      if (!this.$slots.inner) {
        this.animationList()
      }
    },
  },

  methods: {
    animationList() {
      const navbarItem = this.$refs['navbar-item'][0]
      const navbarWrapper = this.$refs['navbar-wrapper']
      const height =
        (navbarItem.getBoundingClientRect().height + 17) * this.links.length
      let timeout
      if (this.open) {
        timeout = setTimeout(() => {
          navbarWrapper.style.height = `${height}px`
          clearTimeout(timeout)
        }, 0)
      } else {
        timeout = setTimeout(() => {
          navbarWrapper.removeAttribute('style')
          clearTimeout(timeout)
        }, 200)
      }
    },
  },
}
